import type { IEventProperty } from '@microsoft/1ds-analytics-js';
import { ValueKind } from '@microsoft/1ds-analytics-js';
import { hashString } from 'owa-analytics-shared/lib/utils/hashString';
import { getApp } from 'owa-config/lib/bootstrapOptions';
import { getPlt } from 'owa-plt-data';
import { createEvent, logOneDSDatapoint } from '../OneDsWrapper';
import getConditionalAccessString from '../utils/getConditionalAccessString';
import type {
    AnalyticsSessionProperties,
    AnalyticsSettingsAddon,
    CoreAnalyticsOptions,
} from 'owa-analytics-types';
import isDevOrAutomationEnvironment from '../utils/isDevOrAutomationEnvironment';
import { getSessionElapseTime } from 'owa-analytics-utils';
import { getBrowserHeight, getBrowserWidth } from 'owa-config';
import { getAriaTenantToken } from 'owa-analytics-shared/lib/settings/getAriaTenantToken';
import { getAnalyticsAddon } from 'owa-analytics-addons';

export async function logSessionDatapoint(analyticsOptions: CoreAnalyticsOptions) {
    // if no aria tokens are passed in, there is no point
    if (
        !analyticsOptions.isModuleSwitch // Do not resend the session data on a module switch
    ) {
        const logSessionProperties = await getAnalyticsAddon<AnalyticsSettingsAddon>(
            'AnalyticsSettings'
        )
            .executeNow()
            ?.getSessionProperties(analyticsOptions.startupInfo);
        const token = getAriaTenantToken(getApp());
        logSessionDatapointInternal(analyticsOptions, token, logSessionProperties);
    }
}

async function logSessionDatapointInternal(
    analyticsOptions: CoreAnalyticsOptions,
    token: string,
    logSessionProperties: AnalyticsSessionProperties
) {
    const {
        userConfiguration,
        accountScopeUserSettings,
        sessionInfo,
        report,
        uberCheck,
        userTypeCheck,
        appSettingsReliabilityCheck,
        sessionAccountInfo,
        sessionImportData,
        webServerForest,
        clique,
        isEUDBCollectorEndpoint,
        isEMEARegion,
        networkIno,
        bootFlights,
        hardware,
    } = logSessionProperties;

    // the event is the same instance
    const featureFlags = analyticsOptions.defaultFeatureFlags || [];
    // the sorted list will use less memory in kusto as it will aggregate better
    featureFlags.sort();

    const navigator = self.navigator;
    // we need to create a seperate datapoint for each event, because there is a bug
    // in the aria code which will force the events to be sent to the same tenant if
    const properties: {
        [key: string]: string | number | boolean;
    } = {
        Features: JSON.stringify(featureFlags),
        UserAgent: navigator.userAgent,
    };

    properties.App = getApp();

    if (sessionInfo.bootType) {
        properties.BootType = sessionInfo.bootType;
    }

    const serviceVersion = sessionInfo.serviceVersion;
    if (serviceVersion) {
        properties.ServiceVersion = serviceVersion;
    }

    const dag = sessionInfo.dag;
    if (dag) {
        properties.dag = dag;
    }

    const sessionElapseTime = getSessionElapseTime();
    if (sessionElapseTime) {
        properties.SessionElapseTime = sessionElapseTime;
    }

    properties.Hardware = JSON.stringify(hardware);
    properties.Capabilities = JSON.stringify({
        webdriver: navigator.webdriver,
        pdfViewerEnabled: navigator.pdfViewerEnabled,
    });

    let hashedEmail: string | undefined;
    if (userConfiguration) {
        const userOptions = userConfiguration.UserOptions || {};
        const viewConfig = userConfiguration.ViewStateConfiguration || {};
        const Dimensions: any = {
            WindowWidth: getBrowserWidth(),
            WindowHeight: getBrowserHeight(),
        };
        if (self.screen) {
            Dimensions.ScreenWidth = self.screen.availWidth;
            Dimensions.ScreenHeight = self.screen.availHeight;
        }

        // Report the import data when present so we know when the user migrated from another Outlook application
        const importData = sessionImportData
            ? {
                  date: sessionImportData.lastImportDate?.toISOString() ?? '',
                  app: sessionImportData.lastImportApp ?? '',
                  correlationId: sessionImportData.lastImportCorrelationId ?? '',
              }
            : undefined;

        // report the account information when present so we know what accounts the user has configured
        const accountInfo = sessionAccountInfo
            ? {
                  // Report account data when present
                  accounts: sessionAccountInfo.totalAccounts,
                  withPuid: sessionAccountInfo.withPuid,
                  office365: sessionAccountInfo.office365,
                  outlookDotCom: sessionAccountInfo.outlookDotCom,
                  google: sessionAccountInfo.google,
                  yahoo: sessionAccountInfo.yahoo,
                  icloud: sessionAccountInfo.icloud,
                  imap: sessionAccountInfo.imap,
                  pop3: sessionAccountInfo.pop3,
                  pst: sessionAccountInfo.pst,
              }
            : undefined;

        properties.Settings = JSON.stringify({
            ReadingPanePosition: userOptions.GlobalReadingPanePositionReact,
            ListViewType: userOptions.GlobalListViewTypeReact,
            ConditionalAccess: getConditionalAccessString(userConfiguration),
            FocusedEnabled: accountScopeUserSettings?.UserOptions?.IsFocusedInboxEnabled,
            FocusedInboxBits: viewConfig.FocusedInboxBitFlags,
            ListViewBits:
                accountScopeUserSettings?.ViewStateConfiguration?.ListViewBitFlags ??
                0 /* Date headers disabled */,
            FolderViewBits: viewConfig.GlobalFolderViewState,
            PreviewText: userOptions.ShowPreviewTextInListView,
            SenderOnTop: userOptions.ShowSenderOnTopInListView,
            InlinePreviews: userOptions.ShowInlinePreviews,
            HoverActions: viewConfig.MailTriageOnHoverActions,
            Ponts: userOptions.NewEnabledPonts,
            Theme: (userOptions.ThemeStorageId || '') + '|' + userOptions.IsDarkModeTheme,
            MasterCategories: accountScopeUserSettings?.MasterCategoryList?.MasterList,
            IsEdu: !!sessionInfo?.isEdu,
            NetworkInfo: networkIno,
            Dimensions,
            AdMarket: accountScopeUserSettings?.AdMarket,
            DisplayDensity: userOptions.DisplayDensityMode,
            ConsumerAdsExperiment: userOptions.ConsumerAdsExperimentMode,
            Domain: userConfiguration.SessionSettings?.TenantDomain,
            ApplicationSettings: report,
            CerberusFlagMatch: uberCheck,
            FlagsUserMatch: userTypeCheck,
            EcsCheck: appSettingsReliabilityCheck,
            KeyboardShortcutsMode: userOptions.KeyboardShortcutsMode,
            WebServerForest: webServerForest,
            Clique: clique,
            BootFlights: bootFlights,
            IsEUDBCollectorEndpoint: isEUDBCollectorEndpoint,
            IsEMEARegion: isEMEARegion,
            XOwaForest: sessionInfo.forest,
            importData,
            accountInfo,
            DefaultFolderNames: userConfiguration.SessionSettings?.DefaultFolderNames,
            DefaultFolderIds: userConfiguration.SessionSettings?.DefaultFolderIds,
            ShowReadingPaneOnFirstLoad: userOptions.ShowReadingPaneOnFirstLoad,
        });
        properties.Dates = JSON.stringify({
            OwaLogon: userOptions.FirstOWALogon,
            MailLogon: userOptions.FirstOWAReactMailLogon,
            PeopleLogon: userOptions.FirstOWAReactPeopleLogon,
            CalendarLogon: userOptions.FirstOWAReactCalendarLogon,
            MiniLogon: userOptions.FirstOWAReactMiniLogon,
            MailboxCreate: userConfiguration.MailboxCreateDate,
            FirstMonarchLogon: userOptions.FirstMonarchLogon,
        });
        const plt = getPlt();
        if (plt) {
            properties.LoadTime = plt;
        }

        const email = accountScopeUserSettings?.SessionSettings?.UserEmailAddress;
        if (email) {
            hashedEmail = hashString(email);
        }
    }

    if (!isDevOrAutomationEnvironment()) {
        const e = createEvent('session');
        e.baseType = 'session';
        e.data = properties;
        if (hashedEmail) {
            e.data.HashEmail = <IEventProperty>{
                value: hashedEmail,
                kind: ValueKind.Pii_Identity,
            };
        }
        logOneDSDatapoint({ tenantToken: token, item: e, analyticsOptions });
    }
}
