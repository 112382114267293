import {
    SCHEMA_VERSION,
    type UTAccountCloud,
    type UTAudience,
    type UTClient,
    type UTUserIdType,
    type UTUserType,
    type UTServiceDeploymentRing,
} from 'owa-unified-telemetry';
import type { UnifiedCommonProperties } from '../types/UnifiedCommonProperties';
import { getHostValue } from 'owa-config/lib/getHostValue';
import { NATIVE } from 'owa-config/lib/types/HostApp';
import { hashString } from 'owa-analytics-shared/lib/utils/hashString';
import type WebSessionType from 'owa-service/lib/contract/WebSessionType';
import type { PhysicalRing } from 'owa-metatags/lib/getPhysicalRing';
import type { AnalyticsUserConfiguration, CoreAnalyticsOptions } from 'owa-analytics-types';
import { getCommonProperties } from '../settings/getCommonProperties';
import { getCachedSettings } from '../settings/getCachedSettings';

let sessionSettings: AnalyticsUserConfiguration['SessionSettings'] | undefined;

// Rebuilds the datapoint's properties collection to the unified schema
export async function addCommonUnifiedPropreties(
    analyticsOptions: CoreAnalyticsOptions
): Promise<UnifiedCommonProperties> {
    const shouldIncludeUserInfo = !!analyticsOptions?.shouldIncludeUserInfoId;
    const userConfiguration = getCachedSettings()?.userConfiguration;
    const commonProperties = await getCommonProperties(analyticsOptions);

    if (!sessionSettings && userConfiguration?.SessionSettings) {
        sessionSettings = userConfiguration.SessionSettings;
    }

    const email = sessionSettings?.UserEmailAddress;
    const userId = sessionSettings?.UserPuid;
    const isConsumer =
        typeof sessionSettings?.WebSessionType !== 'undefined' &&
        sessionSettings?.WebSessionType !== (0).toString();
    const userIDType = isConsumer ? 'MSAPUID' : 'OrgIdPuid';

    return {
        CommonHVAProperties: {
            SessionProperties: {
                ClientId: commonProperties.ClientId || '',
                Client: commonProperties.Host === NATIVE ? 'NativeHost' : 'OWA',
                SchemaVersion: SCHEMA_VERSION,
                Audience: mapRingToAudience(
                    commonProperties.PhysicalRing as PhysicalRing | undefined
                ),
                ServiceDeploymentRing: mapRingToServiceDeploymentRing(
                    commonProperties.PhysicalRing as PhysicalRing | undefined
                ),
                WebHost: getHostValue(),
            },
            AccountProperties: {
                HashedEmail: hashString(email),
                EmailDomain: getDomain(email).toLocaleLowerCase(),
                AccountCloud: mapRingToAccountCloud(
                    commonProperties.PhysicalRing as PhysicalRing | undefined
                ),
                TenantGuid: commonProperties.TenantGuid ?? undefined,
                UserId: shouldIncludeUserInfo ? userId : undefined,
                UserIdType: shouldIncludeUserInfo ? userIDType : undefined,
                UserType:
                    shouldIncludeUserInfo && commonProperties?.UserType
                        ? mapUserType(commonProperties.UserType)
                        : undefined,
            },
        },
    };
}

function mapRingToAccountCloud(ring?: PhysicalRing): UTAccountCloud {
    const rings: {
        [P in PhysicalRing]: UTAccountCloud;
    } = {
        Unknown: 'WorldWide',
        WW: 'WorldWide',
        Dogfood: 'WorldWide',
        SIP: 'WorldWide',
        BlackForest: 'Blackforest',
        DONMT: 'WorldWide',
        MSIT: 'WorldWide',
        Gallatin: 'Gallatin',
        SDFV2: 'WorldWide',
        PDT: 'GCCModerate',
        TDF: 'GCCHigh',
        ITAR: 'DoD',
    };

    return ring ? rings[ring] : 'WorldWide';
}

function mapRingToServiceDeploymentRing(ring?: PhysicalRing): UTServiceDeploymentRing {
    const rings: {
        [P in PhysicalRing]: UTServiceDeploymentRing;
    } = {
        Unknown: 'Unknown',
        WW: 'WW',
        Dogfood: 'Unknown',
        SIP: 'SIP',
        BlackForest: 'Unknown',
        DONMT: 'DONMT',
        MSIT: 'MSIT',
        Gallatin: 'Gallatin',
        SDFV2: 'SDFV2',
        PDT: 'PDT',
        TDF: 'TDF',
        ITAR: 'ITAR',
    };

    return ring ? rings[ring] : 'Unknown';
}

function mapRingToAudience(ring?: PhysicalRing): UTAudience {
    switch (ring) {
        case 'PDT':
        case 'TDF':
            return 'Dev';
        case 'Dogfood':
        case 'SDFV2':
        case 'MSIT':
            return 'Dogfood';
        case 'ITAR':
        case 'SIP':
        case 'BlackForest':
        case 'DONMT':
        case 'Gallatin':
            return 'Prod';
        default:
            return 'Prod';
    }
}

function getDomain(email: string | undefined): string {
    return email?.split('@')?.pop() || '';
}

function mapUserType(userType: string): UTUserType {
    const rings: {
        [key: string]: UTUserType;
    } = {
        c_child: 'ConChild',
        c_premium: 'ConPremium',
        c_shadow: 'ConStandard',
        c_standard: 'ConChild',
        b_edu: 'ComEdu',
        b_standard: 'ComStandard',
        unknown: 'ConStandard',
    };

    return rings[userType] || 'ConStandard';
}
