import type {
    AriaDatapointType,
    AnalyticsOptions,
    FlightControl,
    InternalDatapointOptions,
} from 'owa-analytics-types';
import { getAnalyticsFlightsAndAppSettings } from '../settings/getAnalyticsFlightsAndAppSettings';

export default function getFlightControl(
    datapoint: AriaDatapointType,
    analyticsOptions: AnalyticsOptions,
    eventType: string
): FlightControl | null {
    const datapointOptions = datapoint.getOptions() as InternalDatapointOptions;

    // Check if isCore is disabled in ECS for this datapoint
    if (
        datapointOptions?.isCore &&
        getAnalyticsFlightsAndAppSettings()?.disableIsCoreDatapoints?.includes(
            datapoint.getEventName()
        )
    ) {
        datapointOptions.isCore = false;
    }

    // do not sample datapoints if marked as isCore or in ECS unsample override list, and they are not in ECS sampled override list
    if (datapointOptions?.isCore) {
        return null;
    }

    // The ECS override should priority above all other overrides and treat it as verbose
    if (
        (analyticsOptions?.flightControls &&
            getAnalyticsFlightsAndAppSettings()?.throttledDatapoints?.includes(
                datapoint.getEventName()
            )) ||
        datapointOptions.logAsVerbose
    ) {
        eventType = 'client_verbose';
    }

    // Application that do not initialize analytics, such as Storybook, have an undefined appAnalyticsOptions
    if (analyticsOptions?.flightControls) {
        return analyticsOptions.flightControls[eventType];
    }
    return null;
}
