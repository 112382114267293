import type { QoS_Events as QoS_IsCore_Events } from './isCore';

/**
 * ADD SAMPLED QOS EVENTS HERE
 */
type QoS_Sampled_Events = 'LoadGroupsInLeftNavFromSessionData';

type All_QoS_Events = QoS_IsCore_Events | QoS_Sampled_Events;

/**
 * Adding a QoS event to this list will change it's EventType and add other properties.
 * It will NOT mark it as "isCore", that still needs to be done separatly.
 */
export const allQoSEvents: {
    [key in All_QoS_Events]: 1;
} = {
    // isCore - Un-sampled events
    LoadCalendarModuleE2E: 1,
    meetingMessageAction: 1,
    OpenItemPeek: 1,
    ViewCalendarEvent: 1,
    SwitchCalendarView: 1,
    DeleteEvent: 1,
    MailComposeSend: 1,
    MailComposeNew: 1,
    MailComposeReply: 1,
    MailComposeReplyAll: 1,
    MailComposeForward: 1,
    TnS_CreateFolder: 1,
    SelectMailItem: 1,
    TnS_SelectPivot: 1,
    SwitchMailFolder: 1,
    TnS_MarkItemRead: 1,
    TnS_MarkConversationRead: 1,
    TnS_MarkItemUnread: 1,
    TnS_MarkConversationUnread: 1,
    TnS_MoveItem: 1,
    TnS_MoveConversation: 1,
    TnS_FlagItem: 1,
    TnS_FlagConversation: 1,
    TnS_UnflagItem: 1,
    TnS_UnflagConversation: 1,
    TnS_DeleteItem: 1,
    TnS_DeleteConversation: 1,
    changeCalendarViewType: 1,
    ContactManagement_CreatePersona: 1,
    Search_GetSearchedPersonasContacts: 1,
    ContactManagement_GetPersona: 1,
    ContactManagement_LoadPersonasCompletedAction: 1,
    DistributionLists_DistributionListLoadCompletedAction: 1,
    ContactManagement_UpdatePersona: 1,
    ContactManagement_DeletePersonas: 1,
    AttachmentCreation: 1,
    PreviewAttachmentInSxSE2E: 1,
    SxSDownload: 1,
    AttachmentContextMenuItemDownload: 1,
    AttachmentSurfaceActionDownload: 1,
    AttachmentWellDownloadAll: 1,
    AttachmentWellDownload: 1,
    SearchEndToEnd: 1,
    LoadTimePanelE2E: 1,
    // Sampled events
    LoadGroupsInLeftNavFromSessionData: 1,
};

export const qosEvents = Object.keys(allQoSEvents);
