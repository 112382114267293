import type { AnalyticsOptions, CoreAnalyticsOptions } from 'owa-analytics-types';
import type { StartupAnalyticsInfo } from 'owa-config';
import { isRunningOnWorker } from 'owa-config/lib/isRunningOnWorker';
import { setIsAnalyticsWorker } from './worker/isAnalyticsWorker';
import { setIsAnalyticsWorkerActive } from './worker/isAnalyticsWorkerActive';
import { getAnalyticsFlightsAndAppSettings } from './settings/getAnalyticsFlightsAndAppSettings';

export enum AnalyticsOptionsState {
    NOT_INITIALIZED,
    INITIALIZING,
    INITIALIZED,
}

let updatedAnalyticsOptions: CoreAnalyticsOptions | null = null;
export let analyticsInitilizationState: AnalyticsOptionsState =
    AnalyticsOptionsState.NOT_INITIALIZED;

export function getInitializedAnalytics(): CoreAnalyticsOptions | null {
    return updatedAnalyticsOptions;
}

// This is for the tests only
export function resetUpdatedAnalyticsOptions() {
    updatedAnalyticsOptions = null;
}

export const defaultAnalyticsOptions: AnalyticsOptions = {
    maxErrorsPerSession: 100,
    flightControls: undefined,
    verboseWhiteListEvents: [],
    defaultFeatureFlags: [],
};

export async function initializeAnalytics(
    analyticsOptions: AnalyticsOptions,
    startupAnalyticsInfo: StartupAnalyticsInfo
): Promise<CoreAnalyticsOptions> {
    analyticsInitilizationState = AnalyticsOptionsState.INITIALIZING;

    if (isRunningOnWorker()) {
        setIsAnalyticsWorkerActive(true);
        setIsAnalyticsWorker();
    }

    analyticsOptions = { ...defaultAnalyticsOptions, ...analyticsOptions };

    if (getAnalyticsFlightsAndAppSettings()?.skipFlightControls) {
        delete analyticsOptions.flightControls;
    }

    updatedAnalyticsOptions = { ...analyticsOptions, startupInfo: startupAnalyticsInfo };
    analyticsInitilizationState = AnalyticsOptionsState.INITIALIZED;

    return updatedAnalyticsOptions;
}
